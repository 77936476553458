import { Disclosure } from "@headlessui/react"
import { graphql } from "gatsby"
import Markdown from "markdown-to-jsx"
import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
export default function faq({ data, location }) {
  return (
    <Layout>
      <Seo title="FAQ's" pathname={location.pathname} />
      <section className="max-w-7xl mx-auto w-11/12 py-6 md:py-12 2xl:w-full flex flex-col gap-10">
        <div className="flex flex-col gap-4 items-center">
          <h1 className="text-4xl font-serif text-secondary">
            Frequently Asked Questions
          </h1>
          {/* <p className="max-w-lg text-center mx-auto w-full text-sm text-secondary">
            Check this small FAQ, we help you find ansers on any of your
            questions. <br /> Otherwise, you can contact me and ask directly.
          </p> */}
        </div>

        <ul className="flex flex-col gap-4 max-w-4xl w-full mx-auto">
          {data.FAQ.nodes.map((li, key) => (
            <Disclosure
              as="li"
              key={key}
              className="bg-primary rounded-md w-full flex flex-col items-start divide-y divide-secondary/20"
            >
              <Disclosure.Button className="p-4 w-full text-left flex justify-between items-center">
                <span className="font-semibold text-secondary">
                  {li.frontmatter.title}
                </span>
                <span className="text-2xl">+</span>
              </Disclosure.Button>
              <Disclosure.Panel className="pl-4 md:pl-8 pr-4 py-4 w-full">
                <Markdown>{li.frontmatter.answer}</Markdown>
              </Disclosure.Panel>
            </Disclosure>
          ))}
        </ul>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    FAQ: allMarkdownRemark(filter: { fields: { collection: { eq: "faq" } } }) {
      nodes {
        frontmatter {
          title
          answer
        }
      }
    }
  }
`
